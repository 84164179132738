import React, { useState, useEffect, useRef } from 'react';
import MCIntroLogo from "../../components/magicrop/MCIntroLogo"
import MCDemo from "../../components/magicrop/MCDemo"
import fire from '../../components/Firebase'
import MCDownload from '../../components/magicrop/MCDownload';
import MCHero from '../../components/magicrop/MCHero';
import MCSingleImage from '../../components/magicrop/MCSingleImage';
import MCFooter from './MCFooter';

function MCPageHeader(props) {
    const [data, setData] = useState();
    const [features, setFeatures] = useState();
    const [heroes, setHeroes] = useState();
    const contactRef = useRef(null)

    useEffect(() => {
        if(!data && !features){
            loadData()
        }
    })

    const executeScrollToContact = () => contactRef.current.scrollIntoView()    

    function loadData() {
        fire
        .collection('projects')
        .doc('magicrop')
        .get().then((snap) => {
            setData(snap.data())
        }).catch((err) => console.log(err))

        fire
        .collection('projects')
        .doc('magicrop')
        .collection('features')
        .get().then((snap) => {
            setFeatures(snap.docs)
        }).catch((err) => console.log(err))

        fire
        .collection('projects')
        .doc('magicrop')
        .collection('heroes')
        .get().then((snap) => {
            setHeroes(snap.docs)
        }).catch((err) => console.log(err))
    }

    function getHeroFor(key){
        return heroes.find(hero => hero.data().key == key)
    }

    return <div className="magicrop-body">
            {data ? <div>

                <div className=" container mx-auto">
                        {MCIntroLogo(data, executeScrollToContact)}
                        
                        {props.children}

                        {MCSingleImage({
                            image: "https://firebasestorage.googleapis.com/v0/b/fabio-santo-dev.appspot.com/o/magicrop%2Fcomparison-all.jpg?alt=media&token=ac5fda1f-ff2a-4bcc-b89d-5fc9cf920058",
                            name: "How to Resize a Video with Magicrop"
                        })}

                        {heroes ? MCHero(getHeroFor('main').data()) : null}
                        {MCDemo(data)}
                        {MCDownload(data)}
                    </div>
                    {MCFooter(data)}

            </div> : <p>Let's see...</p>}
                </div>
}

export default MCPageHeader